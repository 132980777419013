import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService, ListOptions } from '@capturum/api';
import { BatchStatus } from '@core/models/batch-status.model';
import { BehaviorSubject, interval, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class BatchStatusService extends ApiService<BatchStatus> {
  protected endpoint = 'batch-status';
  private activeBatches = new Map<string, string>();

  constructor(
    public apiHttp: ApiHttpService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    super(apiHttp);
  }

  public getBatchIdByIdentifier(identifier: string): string {
    return this.activeBatches.get(identifier);
  }

  public setActiveBatch(batchId: string, identifier: string): void {
    this.activeBatches.set(identifier, batchId);
  }

  public removeBatch(batchId: string): void {
    let id = null;

    this.activeBatches.forEach((value, key) => {
      if (value === batchId) {
        id = key;
      }
    });

    this.activeBatches.delete(id);
  }

  public getBatchStatus(id: string, options?: ListOptions): Observable<BatchStatus> {
    return super.get(id, options).pipe(
      tap((response) => {
        if (response.finished_at) {
          this.removeBatch(id);
        }
      }),
    );
  }

  public getIsUpdatedBatch(batchId: string, showSuccessfulMessage = true): Observable<boolean> {
    const subject = new BehaviorSubject<boolean>(false);

    this.getBatchStatus(batchId)
      .pipe(
        switchMap((response: BatchStatus) => {
          if (response.finished_at || response.has_failures) {
            return of({ finished: true, withFailures: response.has_failures });
          } else {
            return this.startInterval(batchId).pipe(
              map<BatchStatus, { finished: boolean; withFailures: boolean }>((batchStatus) => {
                return {
                  finished: !!batchStatus.finished_at || !!batchStatus.has_failures,
                  withFailures: !!batchStatus.has_failures,
                };
              }),
            );
          }
        }),
        catchError(() => {
          return of({ finished: true, withFailures: true });
        }),
        takeUntil(subject.asObservable().pipe(filter(Boolean))),
      )
      .subscribe((response) => {
        if (response.withFailures) {
          this.notificationService.error(
            this.translateService.instant('toast.error.title'),
            this.translateService.instant('toast.error.message'),
          );
        } else if (response.finished && showSuccessfulMessage) {
          this.notificationService.success(
            this.translateService.instant('toast.success.title'),
            this.translateService.instant('dxp.product-grading-details.update-characteristics.success'),
          );
        }

        subject.next(response.finished);
      });

    return subject.asObservable();
  }

  private startInterval(batchId: string): Observable<BatchStatus> {
    return interval(3000).pipe(
      switchMap(() => {
        return this.getBatchStatus(batchId);
      }),
    );
  }
}
